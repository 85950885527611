import React from "react"

const SocialList = () => {
  return (
    <>
      <div>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/royalshadesdxb"
          >
            <i className="fa fa-twitter" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/RoyalShades123"
          >
            <i className="fa fa-facebook" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/royalshadescurtains"
          >
            <i className="fa fa-instagram" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pinterest.com/royalshadescurt"
          >
            <i className="fa fa-pinterest" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/royalshadescurtainsllc/"
          >
            <i className="fa fa-linkedin" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCHsYe4vLoiiw7ObzQqNWWtQ"
          >
            <i className="fa fa-youtube" />
          </a>
        </li>
      </div>
    </>
  )
}

export default SocialList
