import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialList from "../components/sociallist.component"
import ContactForm from "../components/contactform.component"
import { ToastProvider } from "react-toast-notifications"
import { Link, useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ContactPage = () => {
  const pageData = useStaticQuery(graphql`
    query {
      contentfulNavigationPages(idDontChange: { eq: "contactus" }) {
        name
        seo {
          title
          description
          keywords
        }
        slug
        hiddenContent {
          childMarkdownRemark {
            html
          }
        }
        bannerImage {
          fluid {
            src
          }
        }
        contentImage {
          fluid {
            src
          }
        }
        content {
          json
        }
      }
    }
  `)

  const data = pageData.contentfulNavigationPages

  return (
    <Layout>
      <SEO
        title={data.seo.title}
        description={data.seo.description}
        keywords={data.seo.keywords}
      />
      <section
        className="inner-intro bg-img light-color overlay-before parallax-background"
        style={{
          background: `url(${data.bannerImage.fluid.src}) 0 58% no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row title">
            <div className="title_row">
              <h1 data-title="Contact">
                <span>Contact</span>
              </h1>
              <div className="page-breadcrumb">
                <Link to="/">Home</Link> / <span>Contact</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding pt-xs-40">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-8">
              <div className="headeing pb-30">
                <h2>Get in Touch</h2>
                <span className="b-line l-left line-h" />
              </div>
              <ToastProvider placement="top-right">
                <ContactForm />
              </ToastProvider>
            </div>
            <div className="col-md-4 col-lg-4 contact mt-xs-30">
              <div className="headeing pb-20">
                <h2>Contact Info</h2>
                <span className="b-line l-left line-h" />
              </div>
              <div className="contact-info">
                <ul className="info">
                  <li>
                  <a href="https://maps.app.goo.gl/ZoxqrdTSccxkwbuq9" style={{color:"white"}}>
                    <div className="icon fa fa-map-marker" />
                    <div className="content">
                      <p>Warehouse No. 5, 7, 8 &amp; 9, Ras Al Khor Ind Area 2,</p>
                      <p>P.O.Box 115717, Dubai UAE</p>
                    </div>
                    </a>
                  </li>
                  <li>
                    <div className="icon fa fa-phone" />
                    <div className="content">
                      <p>
                        <span>
                          <a href="tel:+97143205086">04 320 5086</a>
                        </span>
                      </p>
                      <p>
                        <span>
                          <a href="tel:+971559908586">+971 55 9908586</a>
                        </span>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon fa fa-envelope" />
                    <div className="content">
                      <p>
                        <a href="mailto:office@royalshadescurtains.com">
                          office@royalshadescurtains.com
                        </a>
                      </p>
                      <p>http://royalshadescurtains.com</p>
                    </div>
                  </li>
                </ul>
                <ul className="event-social">
                  <SocialList />
                </ul>
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="block-title v-line">
            <h2>
              <span>What</span> We Do?
            </h2>
          </div>
          <div className="text-content">
            {documentToReactComponents(data.content.json)}
          </div>
        </div>
        {/* Map Section */}
      </section>
      <div className="embed-responsive embed-responsive-100x400px">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.604333245218!2d55.35201231438787!3d25.18283453846431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67a9406b915f%3A0xa9d90445af082592!2sRoyal+Shades+Curtains+LLC!5e0!3m2!1sen!2sae!4v1555958397884!5m2!1sen!2sae"
          style={{ border: 0 }}
          allowFullScreen
          width={600}
          height={450}
          frameBorder={0}
          title="Location Map"
        />
      </div>
      <div
        className="container"
        style={{ display: "none" }}
        dangerouslySetInnerHTML={{
          __html: data.hiddenContent
            ? data.hiddenContent.childMarkdownRemark.html
            : "",
        }}
      ></div>
    </Layout>
  )
}

export default ContactPage
